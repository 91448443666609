<!--
 * @Description: 分页组件
 * @Autor: Zhongyu
 * @Date: 2021-09-14 14:55:28
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-11 18:01:15
-->
<template>
  <div class="default-pagination">
    <!-- 分页按钮 start -->
    <div class="pagination-left">
      <div class="pagination-sum">共{{ total }}条</div>
      <el-select v-model="pageSize" :popper-append-to-body="false" @change="handleSelectChange">
        <el-option
          :label="item + '条/页'"
          :value="item"
          v-for="item in pageSizeOptions"
          :key="item"
        ></el-option>
      </el-select>
    </div>
    <el-pagination
      background
      :current-page="page"
      layout="prev, pager, next"
      :total="total"
      :page-sizes="pageSizeOptions"
      :pager-count="5"
      @current-change="handlePaginationChange"
      @size-change="handleSelectChange"
    />
    <!-- 分页按钮 end -->
  </div>
</template>

<script>
export default {
  name: "ZPagination",
  props: {
    // 总条数
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pageSizeOptions: [10, 20, 30, 40, 50], // 每页条数组
      page: 1, // 页码
      pageSize: 10 // 每页条数
    };
  },
  methods: {
    /**å
     * 提供父层调用更新页码和每页条数
     * @page 页码
     * @pageSize 每页条数
     */
    setData(page, pageSize) {
      if (page) {
        this.page = page;
      }
      // 筛选传入和每页条数是否在每页条数组中
      let pageSizeOption = this.pageSizeOptions.find(item => {
        return item == pageSize;
      });
      // 当存在赋值于每页条数上
      if (pageSizeOption) {
        this.pageSize = pageSize;
      }
    },

    /**
     * psage 改变时会触发
     * @page 页码
     * @pageSize 每页条数
     */
    handlePaginationChange(page) {
      let pageSize = this.pageSize;
      this.page = page;
      /**
       * 触发父层方法
       */
      this.$emit("change", page, pageSize);
    },

    /**
     * pageSize 改变时会触发
     * @pageSize 每页条数
     */
    handleSelectChange(pageSize) {
      let page = this.page;
      this.pageSize = pageSize;
      /**
       * 触发父层方法
       */
      this.$emit("change", page, pageSize);
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
