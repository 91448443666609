<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 14:33:54
 * @LastEditors: Zhongyu
 * @LastEditTime: 2022-01-19 15:02:35
-->
<template>
  <div class="earnings">
    <div class="personal-center-title">
      收益统计
    </div>
    <div class="earnings-body">
      <div class="earnings-total">
        <el-row :gutter="24">
          <el-col :span="5">
            <span class="grid-content number-red">
              <p class="number">{{ $utils.formatMoney(data.allPrice) }}</p>
              <p class="text">累计收益</p>
            </span>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <p class="number">{{ $utils.formatMoney(data.servicePrice) }}</p>
              <p class="text">服务中冻结收益</p>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <p class="number">
                {{ $utils.formatMoney(data.completePrice) }}
              </p>
              <p class="text">待结算冻结收益</p>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <p class="number">
                {{ $utils.formatMoney(data.settlementPrice) }}
              </p>
              <p class="text">已结算收益</p>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <p class="number">{{ $utils.formatMoney(data.orderPrice) }}</p>
              <p class="text">其他收益</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="earnings-detailed">
        <el-radio-group v-model="tabPosition" @change="changeTrading">
          <el-radio-button label="0">全部</el-radio-button>
          <el-radio-button label="3">服务中</el-radio-button>
          <el-radio-button label="5">待结算</el-radio-button>
          <el-radio-button label="6">已结算</el-radio-button>
          <el-radio-button label="7">其他</el-radio-button>
        </el-radio-group>
        <el-table stripe :data="tableData" style="width: 100%">
          <el-table-column prop="time" label="交易时间"> </el-table-column>
          <el-table-column prop="orderNumber" label="订单号"> </el-table-column>
          <el-table-column prop="remarke" label="收支类型">
            <template slot-scope="scope">
              <span v-show="scope.row.status == 3">服务中</span>
              <span v-show="scope.row.status == 5">待结算</span>
              <span v-show="scope.row.status == 6">已结算</span>
              <span v-show="scope.row.status == 7"
                >其他<span v-show="scope.row.remarke"
                  >({{ scope.row.remarke }})</span
                ></span
              >
            </template>
          </el-table-column>
          <el-table-column prop="money" label="收支金额/元" width="150px">
            <template slot-scope="scope">
              <span>{{ $utils.formatMoney(scope.row.money) }}</span>
            </template>
          </el-table-column>
        </el-table>
        <u-pagination
          v-if="count > 0"
          :total="count"
          @change="changePagination"
        ></u-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import UPagination from "@/components/UPagination";

export default {
  components: {
    UPagination
  },
  data() {
    return {
      data: {
        completePrice: 0,
        orderPrice: 0,
        servicePrice: 0,
        settlementPrice: 0,
        allPrice: 0
      },
      tableData: [],
      tabPosition: "0",
      count: 0,
      tableParams: {
        num: 10,
        page: 1
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  mounted() {
    this.getEarningsStatistics();
    this.getEarningsStatisticsList();
  },
  methods: {
    getEarningsStatistics() {
      let params = {
        userId: this.userId
      };
      this.$api.getEarningsStatistics(params).then(res => {
        if (res.data) {
          res.data.allPrice = 0;
          // if (res.data.completePrice) {
          //   res.data.allPrice += Number(res.data.completePrice);
          // }
          if (res.data.orderPrice) {
            res.data.allPrice += Number(res.data.orderPrice);
          }
          // if (res.data.servicePrice) {
          //   res.data.allPrice += Number(res.data.servicePrice);
          // }
          if (res.data.settlementPrice) {
            res.data.allPrice += Number(res.data.settlementPrice);
          }
          this.data = {
            completePrice: res.data.completePrice,
            orderPrice: res.data.orderPrice,
            servicePrice: res.data.servicePrice,
            settlementPrice: res.data.settlementPrice,
            allPrice: res.data.allPrice
          };
        }
      });
    },

    getEarningsStatisticsList() {
      let params = {
        ...this.tableParams,
        userId: this.userId,
        status: this.tabPosition
      };
      this.$api.getEarningsStatisticsList(params).then(res => {
        if (res.data) {
          this.tableData = res.data.earningsList;
          this.count = res.data.count;
        }
      });
    },

    changePagination(page, pageSize) {
      this.tableParams = {
        num: pageSize,
        page: page
      };
      console.log(this.params);
      this.getEarningsStatisticsList();
    },

    changeTrading() {
      this.tableParams.page = 1;
      this.getEarningsStatisticsList();
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
